import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import './App.css';
import Loading from './loading.svg';

const Page = React.lazy(() => import('./Page'));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={
          <div className="Loader">
            <object type="image/svg+xml" data={Loading}>Loading...</object>
          </div>
        }>
          <Switch>
            <Route exact path="/">
              <Page />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
